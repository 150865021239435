var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as S from './index.styles';
import Spinner from '../../../Spinner';
var TicketCreation = function (_a) {
    var submitTicket = _a.submitTicket, done = _a.done, tenantUser = _a.tenantUser, issueList = _a.issueList, mixpanelTrack = _a.mixpanelTrack, message = _a.message;
    var _b = useState(''), ticketDetails = _b[0], setTicketDetails = _b[1];
    var _c = useState(''), fullName = _c[0], setFullName = _c[1];
    var _d = useState(''), email = _d[0], setEmail = _d[1];
    var _e = useState(''), error = _e[0], setError = _e[1];
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useState(issueList ? issueList[0] : ''), issue = _g[0], setIssue = _g[1];
    useEffect(function () {
        if (tenantUser) {
            var combinedName = "".concat(tenantUser.firstName, " ").concat(tenantUser.lastName);
            setFullName(combinedName.trim());
            setEmail(tenantUser.email);
        }
        setLoading(false);
    }, [tenantUser]);
    var isValidEmail = function (userEmail) {
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(userEmail);
    };
    var handleSubmit = function () {
        if (!ticketDetails.trim()) {
            setError('Please enter a message');
            return;
        }
        if (!fullName.trim()) {
            setError('Please enter your name');
            return;
        }
        if (!isValidEmail(email)) {
            setError('Please enter a valid email');
            return;
        }
        submitTicket(ticketDetails, issue, fullName, email);
    };
    if (loading) {
        return _jsx(Spinner, { small: true });
    }
    return (_jsxs(S.TicketCard, { children: [_jsxs(S.SupportInputContainer, { children: [!tenantUser && (_jsxs(_Fragment, { children: [_jsx(S.InputLabel, __assign({ htmlFor: "email" }, { children: "Email" })), _jsx(S.SupportInput, { onChange: function (e) { return setEmail(e.target.value); }, disabled: done }), _jsx(S.InputLabel, __assign({ htmlFor: "fullName" }, { children: "Full Name" })), _jsx(S.SupportInput, { onChange: function (e) { return setFullName(e.target.value); }, disabled: done })] })), issueList && issueList.length > 0 && (_jsx(S.IssueList, __assign({ onChange: function (e) { return setIssue(e.target.value); } }, { children: issueList.map(function (i) { return (_jsx(S.IssueOption, __assign({ value: i }, { children: i }), i)); }) }))), _jsx(S.TicketLabel, { children: "How can we help?" }), _jsx(S.SupportText, { children: message }), _jsx(S.SupportTextArea
                    // TODO: figure this out
                    , { 
                        // TODO: figure this out
                        onClick: function () { return mixpanelTrack('web_support_ticket_details'); }, onChange: function (e) { return setTicketDetails(e.target.value); }, autoCapitalize: "sentences", disabled: done, placeholder: "Tell us what you are having trouble with" })] }), _jsx(S.ErrorMessage, { children: error }), _jsx(S.Actions, { children: _jsx(S.SubmitButton, __assign({ disabled: done, onClick: handleSubmit }, { children: "Send Message" })) })] }));
};
export default TicketCreation;
