import { Inputs, Modal } from '@apps/common-ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ExerciseTypes, usePromptToScroll } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router';
import BreathlessnessSlider from '../../../../../components/Sliders/BreathlessnessSlider';
import * as S from './index.styles';
import Mixpanel from '../../../../../services/Mixpanel';
import { format } from 'date-fns';
import { useActionLoader } from '../../../../../hooks/useActionLoader';
import { createExerciseBaseline } from '../../../../../state/reducers/coaching/actions';
import VitalTipCard from '../VitalTipCard';
import VitalTipInfo from '../VitalTipInfo';
import { CompletionDate } from '../../../../Learning/ModulesList/ModuleCard/index.styles';

type Props = {
    onClose: () => void
    checkSubmission: Function
    exercisePlanId: number
}

const getDefaultOxygenAnswer = () => localStorage.getItem('defaultOxygenAnswer') === 'true';

const PreExerciseBaseline = ({ onClose, checkSubmission, exercisePlanId }: Props) => {
    const defaultAnswer = getDefaultOxygenAnswer();
    const [oxygenAnswer, setOxygenAnswer] = useState(defaultAnswer);
    const [sliderValue, setSliderValue] = useState(0);
    const { date } = useParams<{date: string}>();
    const ref = useRef<HTMLDivElement>(null);
    const ScrollPrompt = usePromptToScroll(ref, 'More below');
    const { callAction: createBaseline, done, loading } = useActionLoader(createExerciseBaseline, {
        errorMessage: 'Error creating baseline'
    });
    const [showInfo, setShowInfo] = useState(false);

    const handleShowInfo = () => {
        setShowInfo(prevShowInfo => !prevShowInfo);
    };

    const schema = yup.object({
        heartRate: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Heart rate is required').min(25, 'Check your reading again, your HR seems too low.')
            .max(220, 'Check your reading again, your HR seems too high.'),
        spO2: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('SpO2 is required').min(65, 'Check your reading again, your SpO2 seems too low.')
            .max(100, 'It is not possible to have your SpO2 more than 100%.'),
        bpSystolic: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('BP systolioc is required').min(80, 'Check your top BP number again, this appears too low.')
            .max(300, 'Check your top BP number again, this appears too high.'),
        bpDiastolic: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('BP diastolic is required').min(40, 'Check your bottom BP number again, this appears too low.')
            .max(300, 'Check your bottom BP number again, this appears too high.'),
        flowRate: yup.number().when('oxygenQuestion', {
            is: () => oxygenAnswer,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Flow rate is required').min(0, 'Is your oxygen flow rate accurate? This appears outside the expected range.')
                .max(15, 'Is your oxygen flow rate accurate? This appears outside the expected range.'),
            otherwise: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).notRequired()
        })
    });

    const { register, handleSubmit, formState, setError, watch } = useForm({
        resolver: yupResolver(schema)
    });

    if (!exercisePlanId) {
        return null;
    }

    const submitInvalidData = () => {
        // only submit invalid data if all required fields have been filled out
        if (watch('heartRate')
            && watch('spO2')
            && watch('bpSystolic')
            && watch('bpDiastolic')
            && (!oxygenAnswer || watch('flowRate'))
            && !loading) {
            const baseline: ExerciseTypes.ICreatedBaseline = {
                heartRate: Number(watch('heartRate')),
                spO2: Number(watch('spO2')),
                bpSystolic: Number(watch('bpSystolic')),
                bpDiastolic: Number(watch('bpDiastolic')),
                flowRate: watch('flowRate') && oxygenAnswer ? Number(watch('flowRate')) : null,
                breathlessnessLevel: sliderValue <= 1 ? Number(sliderValue) : Math.floor(sliderValue),
                exercisePlanId,
                calendarCreatedAt: format(new Date(), 'yyyy-MM-dd'),
                calendarCompletionDate: format(new Date(), 'yyyy-MM-dd'),
            };
            createBaseline({ baseline });
        }
    };

    const createBaselineSubmission = (data: any) => {
        const { heartRate, spO2, bpSystolic, bpDiastolic, flowRate, completionDate } = data;
        const submission: ExerciseTypes.ICreatedBaseline = {
            heartRate,
            spO2,
            bpSystolic: bpSystolic || null,
            bpDiastolic: bpDiastolic || null,
            flowRate: (oxygenAnswer && flowRate) || null,
            breathlessnessLevel: sliderValue <= 1 ? Number(sliderValue) : Math.floor(sliderValue),
            exercisePlanId,
            calendarCreatedAt: format(new Date(), 'yyyy-MM-dd'),
            calendarCompletionDate: completionDate,
        };
        // set default oxygen answer to the value they last submitted
        localStorage.setItem('defaultOxygenAnswer', `${oxygenAnswer}`);
        checkSubmission(submission);
    };

    return (
        <Modal showModal dismissModal={onClose} showTopBar={false} maxHeight="100vh" maxWidth="1200px" padded={false} closeOnOutsideClick={false}>
            <S.ModalContent ref={ref}>
                <S.TitleBar>
                    <S.TitleContainer>
                        <S.Title>Pre Exercise Baseline</S.Title>
                    </S.TitleContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton onClick={onClose}><FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p></S.CloseButton>
                    </S.CloseButtonContainer>
                </S.TitleBar>
                <S.Content>
                    <S.Form
                      onSubmit={handleSubmit(createBaselineSubmission, submitInvalidData)}
                    >
                        <S.FormContainer>
                            <S.InputsRow>
                                <S.InputContainer>
                                    <S.LabelContainer>
                                        <S.InputLabel htmlFor="heartRate">Heart Rate <br /> (bpm) *</S.InputLabel>
                                    </S.LabelContainer>
                                    <S.InputDiv>
                                        <Inputs.Input
                                          id="heartRate"
                                          onFocus={() => Mixpanel.track('web_pre_exercise_baseline_input', {
                                            input: 'heart rate'
                                        })}
                                          inputSize={Inputs.InputSize.extraSmall}
                                          {...register('heartRate')}
                                        />
                                    </S.InputDiv>
                                </S.InputContainer>
                                <S.InputContainer>
                                    <S.LabelContainer>
                                        <S.InputLabel htmlFor="spO2">SpO2 (%) *</S.InputLabel>
                                    </S.LabelContainer>
                                    <S.InputDiv>
                                        <Inputs.Input
                                          id="spO2"
                                          onFocus={() => Mixpanel.track('web_pre_exercise_baseline_input', {
                                            input: 'spO2'
                                        })}
                                          inputSize={Inputs.InputSize.extraSmall}
                                          {...register('spO2')}
                                        />
                                    </S.InputDiv>
                                </S.InputContainer>
                                <S.InputContainer>
                                    <S.LabelContainer>
                                        <S.InputLabel>Blood Pressure *</S.InputLabel>
                                        <S.HelperText>Systolic / Diastolic</S.HelperText>
                                    </S.LabelContainer>
                                    <S.MultiInputRow>
                                        <S.InputDiv>
                                            <Inputs.Input
                                              id="bpSystolic"
                                              onFocus={() => Mixpanel.track('web_pre_exercise_baseline_input', {
                                                input: 'bp systolic'
                                            })}
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('bpSystolic')}
                                              aria-label="bpSystolic"
                                            />
                                        </S.InputDiv>
                                        <S.Slash>/</S.Slash>
                                        <S.InputDiv>
                                            <Inputs.Input
                                              id="bpDiastolic"
                                              onFocus={() => Mixpanel.track('web_pre_exercise_baseline_input', {
                                                input: 'bp diastolic'
                                            })}
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('bpDiastolic')}
                                              aria-label="bpDiastolic"
                                            />
                                        </S.InputDiv>
                                    </S.MultiInputRow>
                                </S.InputContainer>
                                <S.InputContainerFlowRate>
                                    <S.InputContainer>
                                        <S.LabelContainer>
                                            <S.InputLabel htmlFor="oxygen">Are you on <br /> Oxygen?</S.InputLabel>
                                        </S.LabelContainer>
                                        <S.InputDiv>
                                            <Inputs.Checkbox
                                              id="oxygen"
                                              activeText="Yes"
                                              inactiveText="Yes"
                                              defaultChecked={defaultAnswer}
                                              onChange={() => {
                                            if (oxygenAnswer) {
                                                setError('flowRate', { message: '' });
                                            }
                                            setOxygenAnswer(!oxygenAnswer);

                                            Mixpanel.track('web_pre_exercise_baseline_input', {
                                                input: 'Oxygen'
                                            });
                                        }}
                                              disabled={false}
                                            />
                                        </S.InputDiv>
                                    </S.InputContainer>
                                    {oxygenAnswer && (
                                    <S.InputContainer>
                                        <S.LabelContainer>
                                            <S.InputLabel htmlFor="flowRate">Flow rate <br /> (L/min) *</S.InputLabel>
                                        </S.LabelContainer>
                                        <S.InputDiv>
                                            <Inputs.Input
                                              id="flowRate"
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('flowRate')}
                                              onFocus={() => Mixpanel.track('web_pre_exercise_baseline_input', {
                                            input: 'flow rate'
                                        })}
                                            />
                                        </S.InputDiv>
                                    </S.InputContainer>
                                )}
                                </S.InputContainerFlowRate>
                            </S.InputsRow>
                            {!!formState.errors && (
                            <S.ErrorContainer>
                                {Object.values(formState.errors).filter((e => e?.message !== '')).map((e) => <S.ErrorMessage role="alert">{e?.message}</S.ErrorMessage>)}
                            </S.ErrorContainer>
                            )}
                        </S.FormContainer>
                        <BreathlessnessSlider value={sliderValue} width="400px" onChange={(e: any) => setSliderValue(e.target.value)} />
                        <S.SubmitRow>
                            <S.SubmitButton
                              type="submit"
                              onClick={() => Mixpanel.track('web_pre_exercise_baseline_submit')}
                            >
                                Submit Results
                            </S.SubmitButton>
                        </S.SubmitRow>
                    </S.Form>
                    <S.VitalTip>
                        {!showInfo && <VitalTipCard onClick={handleShowInfo} />}
                        {showInfo && <VitalTipInfo onClick={handleShowInfo} />}
                    </S.VitalTip>
                </S.Content>
                <ScrollPrompt />
            </S.ModalContent>
        </Modal>
    );
};

export default PreExerciseBaseline;
